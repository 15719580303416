html{height:100%;}

body {
	background-color: #F0F0F0;
    font-weight: 300;
    position: relative;
	padding: 0;
}

body,
.body-copy {
	font-family: @body-font;
	font-size: 15px;
	color: @body-color;
}

.title-case {
    text-transform: capitalize !important;
}

.green-text {
    color: @abet-green;
}

.body-copy { text-transform: none !important; }

.body-color { color: @body-color !important; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: @abet-orange;
	font-family: @header-font !important;
	font-weight:600;
    text-transform: uppercase;
}

.environment-banner {
    background: @abet-orange;
    color: white;
    font-family: @header-font;
    height: 45px;
    padding: 10px 5px 8px;
    text-align: center;
    width: 100%;
 }

.environment-banner.fixed {
    position: fixed;
    z-index: 10;
}

.environment-banner label,
.environment-banner i.fa {
    color: white !important;
}

div#cl-wrapper.banner-open {
    margin-top: 44px;
}

h2 .body-copy {
    font-size: 24px;
    color: #bbb;
}

label,
.header-font {
    font-family: @header-font;
}

.header-font {
    font-weight: 300;
}

strong {
    font-weight: 700;
}

.bold {
    font-weight: bold;
}

.btn[disabled] {
    opacity: .45;
    pointer-events: unset;
    cursor: not-allowed !important;
}

.btn-group button:not(:last-of-type):not(:hover) {
    border-right: 0;
}

.popover-inner,
.popover-content,
.breadcrumbs,
input,
select,
multiselect-dropdown .dropdown-toggle,
.dropdown-menu,
.help-block,
.nav-tabs li {
    font-family: @header-font;
    font-weight: 400 !important;
}

.popover-content {
    text-transform: none;
}

.orange {
    color: @abet-orange !important;
}

[ng-click]:hover,
[ng-click] > *:hover,
[ngf-select]:hover,
[ui-sref]:hover,
[data-toggle="collapse"],
a label:hover {
    cursor: pointer;
}

i.fa[ng-click]:hover,
[ngf-select]:hover {
    color: @abet-orange;
}

ul.spacing li {
    margin-bottom: 8px;
}

ul.spacing li >ul  {
    margin-top: 8px;
}

.columns {
    display: flex;
}

.columns.vertical-center > * {
    align-self: center;
}

.columns > *:not(.radio):not(.shrink-to-fit) {
    flex-grow: 1;
    padding: 0 15px;
}

.columns > :last-child:not(.padding-right) {
    padding-right: 0 !important;
}

.columns > :first-child {
    padding-left: 0 !important;
}

.columns.center {
    align-items: center;
}

.columns.input > :first-child {
    max-width: 20px;
}

.columns > table {
    width: auto;
    flex-shrink: 0;
}

.columns > button,
.shrink-to-fit {
    flex-grow: 0;
}

.columns > .flex-right {
    display: flex;
    align-items: baseline;
    justify-content: right;
}

.columns .flex-shrink {
    flex-shrink: 0;
    flex-grow: 0 !important;
    padding: 0 !important
}

.list-columns .list-btns {
    background: #ddd;
    border-radius: 6px;
    font-family: @header-font;
    margin: 15px;
    padding: 10px;
}

.list-columns .list-btns li:last-child {
    padding-bottom: 0;
}

.list-columns .list-btns button.btn {
    margin-bottom: 0 !important;
}

.list-columns .list-btns > div:not(:first-of-type) button.btn {
    border-top: none;
}
/* Collapse */
.collapsible .columns:hover,
.collapsible .columns.open,
.columns[data-toggle="collapse"]:hover {
    background: #f5f5f5;
}

.collapsible > .columns > :hover {
    cursor: pointer;
}

.columns[data-toggle="collapse"] {
    padding: 5px 0;
    align-items: center;
}

.collapsible > .columns,
.collapsible .body {
    border: 1px solid #ddd;
    box-shadow: 1px 1px 0 0 #f5f5f5;
    padding: 8px 15px;
    transition: all 0.5s ease;
}

.collapsible .body {
    overflow: auto;
}

.tracking-container .collapsible .body {
    padding: 25px;
}

.tracking-container .identifier {
    width: 28px;
    text-align: center;
}

table.due-response ul.no-style {
    margin-top: 10px;
}

.collapsible:not(:last-of-type) > .columns,
.collapsible:not(:last-of-type) .body {
    border-bottom: none;
}

.collapsible:last-of-type .body {
    border-top: none;
}

.collapsible:first-of-type > .columns {
    border-radius: 5px 5px 0 0;
}

.collapsible:last-of-type .body {
    border-radius: 0 0 5px 5px;
}

#error.index-error {
    display: none;
}

.omnisearch ul.dropdown-menu li {
    padding: 3px 0;
}

a {
    color: @link-color;
    letter-spacing: 0.05em;
    outline: 0 none;
}

del,
.deleted {
    background: @red + 70%;
}

ins,
.inserted {
    text-decoration: none;
    background: @abet-green + 40%;
}

.disabled-widget {
    opacity: .4;
    cursor: not-allowed;
}


.widget.error {
    border-left: 5px solid @red;
}

.widget hr,
.modal hr {
    border-color: #eee;
}

hr,
.notice hr {
    border-color: #ddd;
}

a:focus, a:hover, a:active {
    outline: 0 none;
    text-decoration: none;
    color: @link-color - 20%;
}

.widget a:not(.btn):not(.icon):not(.not-entered),
a.underline,
.drop-box.multiupload a {
    border-bottom: 1px dashed #aaa;
    text-transform: none;
}

.widget a:not(.btn):not(.icon):not(.not-entered):hover,
a.underline:hover,
.drop-box.multiupload a:hover {
    border-bottom-color: @link-color - 20%;
    cursor: pointer;
}

.widget .buttons a:not(.btn):not(.not-entered) {
    font-size: 15px;
}

p {
    line-height: 22px;
}

b {
    font-weight: 400;
}

.modal blockquote {
    font-size: unset;
    border-color: #ccc !important;
}

blockquote p {
    font-size: 15px;
}

ul {
    padding-left: 30px;
}

ol li {
    padding: 3px 10px;
}

label {
    font-size: 14px;
}

.inline-box {
    border: 1px solid #ccc;
    padding: 15px 12px;
    border-radius: 5px;
    margin: 15px 0;
}

.block-flat,
.bottom-navigation-container button,
.editor-tool-container .comment-body {
    border-radius: 3px;
    border-top: none;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #e2e2e2;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.bottom-navigation-container button.btn-primary {
    border-left-color: #ddd;
    border-right-color: #ddd;
    border-bottom-color: #bbb;
}

.overflow-hidden {
    overflow: hidden;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-y {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.no-margin-x {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

#pcont {
    margin-left: 216px;
    position: relative;
}

#pcont.container-fluid {
    padding: 0;
    overflow: hidden;
}

.container-fluid {
    padding: 0;
}

[uib-popover-template]:not(.disabled):hover,
[uib-popover]:not(.disabled):hover {
    cursor: pointer;
}

[popover-enable="false"]:hover {
    cursor: initial;
}

[popover-enable="false"]:hover a:hover {
    cursor: pointer;
}

.help-block {
    color: #aaa;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 0;
    line-height: 18px;
}

p .help-block {
    margin-top: 15px;
}

h5 span.inline-heading {
    font-size: .95em;
    font-style: italic;
    font-weight: 300;
    margin-left: 5px;
    text-transform: none;
}
/* WELCOME */
.frameless-container {
    margin: 20px;
}

.frameless-container header {
    margin-bottom: 30px;
    text-align: center;
}

.frameless-container .logo {
    background: url(../images/ABET_RGB_SM.png) top center no-repeat;
    background-size: 36px 36px;
}

.frameless-container .panel .panel-title a {
    border-bottom: none;
}

.frameless-container .block-flat .content .panel h4 {
    margin-top: 5px;
}

.standalone {
    color: #555;
    font-family: Campton,Arial,sans-serif;
    font-size: 16px;
}

.standalone p {
    font-weight: 400;
}

.standalone header,
.standalone main {
    width: 850px;
    margin: 0 auto;
}

.standalone header {
    margin-top: 40px;
    background: #000;
    color: #fff;
    height: 64px;
    padding: 15px 30px 15px 15px;
    /*display: flex;
    flex: 0 auto;
    justify-content: space-between;*/
}

.standalone .logo {
    width: 64px;
}

.standalone main {
    margin-bottom: 60px;
}

.standalone footer {
    margin-top: 20px;
}

.standalone h2, h4 {
    text-transform: capitalize;
}

.standalone h2 {
    font-size: 34px;
    font-weight: 700;
}

.standalone h4 {
    color: @abet-gray;
    font-size: 22px;
}

.standalone.moved header {
    margin-top: 10vh;
}

.standalone.moved h2 {
    margin-bottom: 10px;
}

.standalone.moved h4 {
    margin-top: 0;
    margin-bottom: 40px;
}

.standalone.moved .widget {
    padding: 20px;
}

.standalone.moved .notice {
    margin: 30px 0 0;
}

.logo-grey {
    background: url(../images/ABET_GREYTXT.png) top center no-repeat;
    height: 48px;
    text-indent: -9999999px;
    background-size: 42px 42px;
}
/* READINESS REVIEW */
.readiness-review {
    margin: 30px 50px 50px;
}

.readiness-review p {
    margin-bottom: 15px;
}

.readiness-review .item-container {
    margin-bottom: 20px;
}

.readiness-review .item-container p {
    margin-top: 15px;
}
/* ERROR VIEW */
.error-view {
    margin: 8%;
    text-align: center;
}

.error-view div {
    display: inline-block;
    text-align: left;
}
/* Contact btn */
#contact-btn {
    bottom: 0;
    position: fixed !important;
}

#contact-btn:hover {
    width: 100%;
}

#contact-btn a {
    border-top: none;
}

#contact-btn #address {
    display: none;
}

#contact-btn a:hover #address {
    display: inline-block;
    width: 100%;
}

.back-to-top {
    position: fixed;
    bottom: 0;
    right: 0px;
    text-decoration: none;
    color: #FFF;
    background-color: rgba(39,41,48,0.8);
    font-size: 12px;
    padding: 9px 12px;
    display: none;
}

.back-to-top i {
    font-size: 15px;
}

.back-to-top:hover {
    color: #FFF;
    background-color: rgba(39,41,48,1);
}

.back-to-top:focus {
    color: #FFF;
}

.btn {
    font-family: @header-font;
    font-weight: 700;
}

.btn.no-margin-bottom {
    margin-bottom: 0px !important;
}

.btn-primary, .btn-primary:focus {
    background-color: @abet-orange !important;
}

.btn-danger, .btn-danger:focus {
    background-color: @abet-blue !important;
}

.btn.no-margin {
    margin: 0 !important;
}

label,
.update-modal .username h4 {
    color: @abet-gray;
    font-family: @header-font;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.update-modal .username h4 {
    font-size: 13px;
    font-weight: 600;
}

td.check {
    width: 30px;
}

.logged-in-user-heading {
    color: @abet-gray;
    display: inline;
    font-size: 16px;
    letter-spacing: 0.05em;
    margin: 0;
    text-align: center;
}
/* TAB NAVIGATION */
.tab-navigation-container {
    margin-bottom: 40px;
}

.tab-navigation-container ul {
    display: flex;
    padding-left: 0;
}

.tab-navigation-container ul li {
    display: inline;
    flex: 1;
    margin-right: 2px;
    transition: .8s;
}

.tab-navigation-container li.active {
    border-color: @abet-orange;
    color: @abet-orange;
    flex: 2;
}

.tab-navigation-container li:not(.locked):hover {
    cursor: pointer;
}

.tab-navigation-container li[popover-enable="true"]:hover {
    cursor: initial;
}

.tab-navigation-container li:not(.locked):not(.active):hover div.info {
    background: #b5b5b5;
}

.tab-navigation-container li span.name {
    display: block;
    font-family: @header-font;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 0 0.5em;
}

.tab-navigation-container li div.info {
    background: #ccc;
    color: white;
    font-family: @header-font;
    font-weight: 600;
    height: 20px;
    margin-bottom: 15px;
    padding: 0 10px;
    transition: .3s;
}

.tab-navigation-container li:not(.locked):active {
    margin-bottom: -8px;
    transition: none;
}

.tab-navigation-container li:not(.locked):active div.info {
    margin-bottom: 13px;
    margin-top: 8px;
    transition: .2s;
}

.tab-navigation-container li.active div.info {
    background: @abet-orange;
}

.tab-navigation-container div.info i.fa {
    color: #999;
}

.tab-navigation-container div.info div.popover {
    color: @body-color;
}

.tab-navigation-container li.active div.info i.fa {
    color: white;
}

.bottom-navigation-container {
    border-top: 1px solid #d9d9d9;
    margin-top: 50px;
    padding: 40px 0 10px;
}

.bottom-navigation-container button:hover {
    background: white;
}

.bottom-navigation-container .col-xs-6 button {
    width: 50%;
}

.bottom-navigation-container .col-xs-12 button {
    width: 25%;
}

ul.error-list,
ul.error-list li {
    display: block;
    font-size: 15px;
    font-weight: 300;
    list-style-type: disc !important;
}

ul.error-list li:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.tab-navigation-container li.locked .name {
    color: #ccc;
}

.tab-navigation-container li.locked .info {
    background: #ddd;
}
/* USER-FACING STYLES */
.single-column {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.single-column .rfr-component.campus-locations {
    margin-top: 0;
    text-align: left;
}
/* INSTITUTIONAL REVIEW OPTS */
.editable-text {
    font-size: 1.2em;
    border-bottom: 1px dotted;
}

a.editable-text {
    text-decoration: none;
}

.rfr-component.campus-locations {
    margin-top: 25px;
    text-align: center;
}
/* MANAGE CONTACTS */
ul.item-listing {
    list-style: none;
    margin: 0;
    padding: 0;
}

.single-contact .address-block .edit {
    float: left;
    width: 27px;
}

.single-contact .address-block .body {
    overflow: hidden;
}

.item-container {
    border: 1px solid #dadada;
    border-radius: 3px;
    margin-top: 20px;
    padding: 10px;
}

.item-container .disabled {
    color: #d5d5d5;
}

.item-container.contact.with-delegate {
}

.item-container h5 {
    color: @abet-gray;
    margin-top: 0;
}

span.new-program {
    color: @abet-orange;
    font-size: 0.9em;
    margin-right: 5px;
    text-transform: uppercase;
}

.search-results h5 {
    padding-left: 5px;
}

ul.details li {
    padding: 0 0 5px 5px;
}

.item-listing.contacts .item-container i.fa-edit {
    margin-right: 5px;
}

.item-listing.contacts a:not(.not-entered) {
    border-bottom: none;
}

a.not-entered,
div.single-delegate div.entered, a.no-contact {
    background: #f5f5f5;
    border: 1px dashed #ccc;
    border-radius: 3px;
    display: block;
    margin-top: 10px;
    padding: 10px 5px;
    text-align: center;
    text-decoration: none;
}

a.not-entered:hover, a.no-contact:hover {
    background: #e5e5e5;
}

div.single-delegate div.entered {
    border-color: #dadada;
    border-style: solid;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
}

div.single-delegate div.entered div.buttons {
    padding-right: 3px !important;
}

.modal-footer.full {
    text-align: unset;
    padding: 30px 20px;
}

.modal .dropdown-menu,
.results-container {
    margin: 3px 0 10px;
    padding: 0;
}

.modal .dropdown-menu {
    margin-top: 0 !important;
}

.results-container {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.176);
    position: static;
}

.no-results .text {
    font-family: @header-font;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.no-results span, .no-results a {
    display: block;
}

.no-results a {
    padding: 10px;
}

.no-results a:hover {
    cursor: pointer;
    background: #d5d5d5;
}

.modal .dropdown-menu li a {
    white-space: normal;
}

.multiselect-container button {
    margin-bottom: 0 !important;
    padding: 6px 11px !important;
}

.no-results a .text {
    color: @body-color
}

.modal .dropdown-menu:not(.multiselect-dropdown-menu) li a,
.no-results {
    background: #eee;
    color: @body-color;
    display: block;
    border-bottom: 1px dashed #aaa;
}

.modal .dropdown-menu:not(.multiselect-dropdown-menu) li a,
.no-results:not(.with-link) {
    padding: 10px;
}

.modal .dropdown-menu.multiselect-dropdown-menu {
    margin-left: 0;
}

.modal .multiselect-container {
    position: relative;
    width: 100%;
}

.modal .multiselect-container button.dropdown-toggle {
    text-align: left;
    width: 100%;
}

.multiselect-dropdown-menu .checkbox {
    margin: 0;
}

.modal .dropdown-menu li:hover a {
    background: #ddd;
}

.modal .dropdown-menu li:last-child a,
.no-results {
    border: none;
}

.modal .dropdown-menu li span.name strong {
    color: #333;
}

.modal .dropdown-menu li span.address {
    display: inline-block;
    margin-top: 3px;
}
/* RFR TABLE */
table.rfr-list h3 {
    display: inline-block;
    margin: 0 15px 0 0;
}

.tabular-list.rfr {
    margin-top: 25px;
}

.tabular-list.rfr,
.tabular-list th,
.tabular-list tr {
    border: 1px solid #dadada;
}

.tabular-list tr,
.tabular-list th {
    text-align: center;
    background: none !important;
}

.tabular-list th,
.tabular-list td {
    padding: 2px 5px !important;
}

.tabular-list:not(.preview-mode) tr:not(:first-child):not(.disabled):hover {
    cursor: pointer;
}

.tabular-list:not(.preview-mode) tr:not(:first-child):not(.disabled):hover td {
    background: #f5f5f5;
}

.tabular-list:not(.preview-mode) tr:not(:first-child):hover a,
.tabular-list h5 {
    color: @abet-gray;
}

.tabular-list:not(.preview-mode) tr.disabled,
.tabular-list:not(.preview-mode) tr.disabled h5 {
    color: #aaa !important;
}
/* Validation */
.danger {
    color: @danger-red !important;
}

input.ng-invalid-required:not(#username):not(#password),
select.ng-invalid-required,
textarea.ng-invalid-required,
.form-group .dropdown-toggle.invalid,
.modal-footer span.validation-key.invalid-required {
    border-left: 3px solid @danger-red;
}

input.ng-valid:not(#username):not(#password):not(#omnisearch),
select.ng-valid,
textarea.ng-valid,
.form-group .dropdown-toggle:not(.valid):not(.invalid),
.modal-footer span.validation-key.optional {
    border-left: 3px solid #ccc;
}

.assignment-tool .filters input.ng-valid-required {
    border-left: 3px solid #ccc !important;
}

input.ng-valid-required:not(#username):not(#password),
select.ng-valid-required,
textarea.ng-valid-required,
.form-group .dropdown-toggle.valid,
.modal-footer span.validation-key.valid-required {
    border-left: 3px solid @abet-green;
}

.ng-touched.ng-invalid {
    border: 1px solid @danger-red !important;
    border-left-width: 3px !important;
}

input[type="file"].ng-touched.ng-invalid {
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
}

li.div-list > div:not(:last-of-type) {
    margin-bottom: 5px !important;
}

li.div-list div.row:nth-child(2n) {
    background: #eee;
}

.success:not(.notice) {
    background: #eee;
    border-left: 5px solid @abet-green;
    display: inline-block;
    font-family: @header-font;
    margin: 10px 0 20px;
    padding: 5px;
}

.form-group .error {
    padding: 10px;
}
/* NOTICE */
.notice,
.form-group .error {
    font-family: @header-font;
    margin-bottom: 40px;
    position: relative;
}

.notice {
    color: @abet-gray;
    display: table;
    background: white;
}

.notice.full-width {
    width: 100%;
}

.notice.alt {
    font-weight: 400;
}

.notice.alt .main {
    background: none;
}

.notice.help-text {
    color: @abet-gray;
}
.notice.slim-alert {
    color: white;
}


.notice.alt .main {
    padding: 14px 20px 4px 0;
}

.notice.slim-alert .btn {
    border: none;
    margin: 0 0 0 10px !important;
}

.notice.slim-alert .btn label {
    font-size: 12px;
}

.notice > .icon {
    color: white;
    display: table-cell;
    font-size: 50px;
    text-align: center;
    vertical-align: middle;
    width: 100px;
}

.notice.alt > .icon {
    font-size: 35px;
    width: 65px;
    border-radius: 3px;
}

.notice.help-text > .icon {
    color: @abet-gray;
}

.notice .main {
    background: white;
    padding: 26px 30px 18px;
}

.notice.success,
.notice.success > .icon {
    background: @abet-green;
}

.notice.error,
.notice.error > .icon {
    background: @red;
}

.notice.success {
    padding: 0;
}

.notice.help,
.notice.info,
.notice.help > .icon,
.notice.info > .icon {
    background: @abet-blue;
}

.notice.warning,
.notice.warning > .icon {
    background: @abet-yellow;
}

.notice.help-text,
.notice.help-text > .icon {
    background: #ededed;
}

.notice .btn,
.notice button,
.notice p label {
    margin: 0;
}

.notice a {
    /*color: @abet-gray;*/
    font-weight: 400;
}

.notice .title .btn,
.notice .title a {
    margin-left: 10px;
}

.notice label[for] {
    text-transform: none;
    font-size: 1.1em;
    letter-spacing: unset;
    font-weight: inherit;
    margin-top: 15px;
}

/* NOTICE (SPECIAL CASES) */
.notice-stack .notice {
    margin-bottom: 20px;
}
.notice-stack .notice .main {
    padding: 18px 30px 12px;
}

.notice .notice-msg {
    background: @abet-yellow;
    display: inline-block;
    color: #222 !important;
    padding: 2px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.notice .notice-msg i { color: #666; }
.modal:not(.page-color) .notice,
.single .notice,
.widget .notice {
    box-shadow: unset;
    border: none;
}

.modal .notice {
    margin-bottom: 20px;
}

.modal .notice.help-text {
    margin-bottom: -10px;
}

.single .notice {
    width: 100%;
}

.widget .errors .notice.warning {
    margin: 10px 0 20px;
}

.modal:not(.page-color) .notice:not(.alt) .main,
.single .notice .main,
.widget .notice .main,
.block-flat .notice .main {
    background: #eee;
}

.modal .errors .notice.warning .main,
.single .errors .notice.warning .main,
.widget .errors .notice.warning .main {
    padding: 10px;
}
/* RFR SIGNATURE */
.rfr-signature-container {
    margin: 0 auto;
    width: 60%;
}

.rfr-signature-container p,
.rfr-signature-container div {
    margin-bottom: 15px;
}
/* EDIT CAMPUS MODAL */
.location-container .column:nth-child(2n) {
    padding-right: 0;
}

.location-container .column:nth-child(2n+1) {
    padding-left: 0;
}

.column .location-header {
    margin-left: 0;
    margin-right: 0;
}

.location-header {
    border: 1px dashed #bbb;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 15px 0 10px !important;
}

.location-header h5 {
    margin: 0 0 8px;
}

ul.dropdown-menu + .form-control {
    margin-top: 10px;
}

.column textarea {
    resize: none;
}
/* ERRORS */
span.validation-errors div.popover {
    max-width: 550px;
}

.login-container .error {
    /*border: 1px solid @abet-orange;
background: fade(@abet-orange, 10%);*/
    background: @abet-yellow;
    font-family: @header-font;
    font-size: 14.5px;
    position: relative;
}

.error-container .errors label {
    margin-left: 10px;
}

.error-container .errors,
.login-container .error {
    padding: 20px 10px 10px;
    margin-bottom: 20px;
}

.login-container .error {
    padding: 10px;
}
/*.form-group .error,
.single .error {
display: inline-block;
padding: 2px 5px;
margin-bottom: 8px;
}*/
.login-container .error {
    margin-bottom: 20px;
}

input.campus-name {
    margin-bottom: 10px;
}

.help-block.instructions {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 10px;
}

.success-color {
    color: @abet-green !important;
    transition: color 1s linear;
}

.warning-color {
    color: @abet-yellow !important;
    transition: color 1s linear;
}

.danger-color {
    color: @red !important;
    transition: color 1s linear;
}

.error-container .title .fa {
    margin-right: 5px;
}

div.popover {
    min-width: 200px;
}

.nav-user-container .impersonate,
.nav-user-container .impersonate i.fa {
    color: green !important;
}

.nav-user-container button.impersonated {
    background: #333 !important;
}

.nav-user-container button.impersonated:hover .impersonate,
.nav-user-container button.impersonated:hover .impersonate i.fa {
    color: red !important;
}

.designations {
    font-style: italic;
}

.search-results.rfr h5 span.commissions {
    color: @body-color;
    font-family: @body-font;
    font-style: italic;
    margin-left: 10px;
}

.search-results.rfr ul.details li {
    background: none;
    padding: 3px 0;
}

.search-results.rfr ul.details li.status {
    font-style: italic;
}

div.help-popover {
    display: inline-block;
}

div.help-popover-message {
    font-family: @body-font;
    font-weight: 400;
    text-transform: none;
}

.inline-button {
    display: flex;
}

.inline-button button {
    margin-left: 10px;
}

div.accepted-rfr span.accepted-date {
    color: @abet-orange;
    display: block;
    font-family: @header-font;
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
}

.terms-accepted-by {
    background: #ddd;
    padding: 6px 10px;
    display: inline-block;
    margin: 10px 0 20px;
    font-family: monospace;
}

.terms-accepted-by ul {
    margin: 0;
}

.reminder {
    border: 1px solid #ccc;
    margin-bottom: 40px;
    padding: 20px 10px 10px;
}

.reminder:not(.body-font) {
    font-family: @header-font;
    font-weight: 600;
}

.reminder.success,
.reminder.info {
    background: fade(@abet-green, 40%);
    border-color: @abet-green;
}

.reminder.warning {
    background: fade(@abet-yellow, 40%);
    border-color: darkorange;
}

.reminder.error {
    background: fade(orangered, 40%);
    border-color: orangered;
}

button.btn.btn-add-new {
    margin-bottom: 20px !important;
}
//JSON Compare
.compare-container h5 {
    margin-bottom: 20px;
}

.compare-container label {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
}

.compare-container a {
    color: @body-color;
}

.compare-container a:hover {
    color: @abet-orange;
}

.compare-container .row {
    padding: 0;
}

.compare-container .compare-item {
    background: #f5f5f5;
    border: 1px solid #ccc;
}

.compare-container .compare-item.second {
    margin-left: -1px;
}

.compare-container .section:not(:last-of-type) {
    margin-bottom: 20px;
}

.compare-container .section.programs h5 {
    margin-bottom: 10px;
}

.compare-container .program-name {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    padding-bottom: 5px;
}

.compare-container .programs .row label:not(.program-name) {
    padding-left: 30px;
}

.changed-removed {
    background-color: fade(#ff8080, 70%) !important;
}

.changed-added {
    background-color: fade(#00e68a, 40%) !important;
}

.changed-changed {
    background-color: fade(#ff944d, 40%) !important;
}

.modal-header button {
    margin-top: 5px;
}

.modal-body .edit-fields, .synopsis {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.modal-body .btn-container {
    margin-top: 20px;
    text-align: center;
}

.modal-body .btn-container.text-right {
    text-align: right;
}

.modal-body .btn-container.text-left {
    text-align: left;
}

.modal-body .edit-fields .row + .btn-container {
    margin-top: 0;
}

.modal-body iframe {
    min-height: 70vh;
}

.separator-bottom {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.separator-top {
    border-top: 1px solid #eee;
    margin-top: 20px;
    padding-top: 20px;
}

.page-header .opt-out-date {
    color: @abet-gray;
    font-size: .7em;
    letter-spacing: 0.03em;
    margin-left: 15px;
}

.page-header .page-category,
.page-header .page-title {
    margin-right: 5px;
}
/* ASSIGN AND RELEASE */
.statistics h5 {
    color: @abet-orange;
}

.assignment-summary-container {
    height: 350px;
    margin-top: 10px;
    overflow-y: scroll;
}

.assignment-summary-container.item-container {
    padding-top: 0;
}

table.assignment-summary th {
    background: white;
    padding: 10px 5px 0;
    position: sticky;
    top: -35px;
}

table.assignment-summary th:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #dadada;
}

table.assignment-summary tr:nth-child(2) td {
    border-top: 0;
}

table.assignment-summary,
table.assignment-summary th,
table.text-center th {
    text-align: center;
}

table.assignment-summary td table td:not(:last-child),
td.button-col {
    width: 5%;
    vertical-align: top;
}

table.assignment-summary td ul,
table.assignment-summary .text-left,
table.text-center .text-left {
    text-align: left;
}

.assignment-summary th .tooltip-dir label {
    margin-bottom: 7px;
}

.assignment-summary .status i {
    font-size: 0.8em;
}

.tc-assignment-buttons button {
    margin-bottom: 0 !important;
}

.tc-assignment-buttons .dropdown-menu {
    margin: 0 !important;
    padding: 0;
}

.tc-assignment-buttons .dropdown-menu a {
    border-bottom: none !important;
    padding: 8px 10px;
}

.tc-assignment-buttons .dropdown-menu > li > a:hover {
    background: @abet-orange;
}

table td.short {
    width: 20%;
}

.modal-xl {
    width: 1200px;
}

input.number-range {
    display: inline-block;
    margin: 0 !important;
    width: 100px;
}
/************ ASSIGNMENT TOOL ************/
body.assignment-tool {
    background: white;
}

.assignment-tool-body {
    padding: 70px 40px 10px;
}

.assignment-tool-body .filters button {
    margin: 0 !important;
}

.assignment-tool,
.assignment-tool .popover,
.assignment-tool h1,
.assignment-tool h2,
.assignment-tool h3,
.assignment-tool h4,
.assignment-tool h5,
.assignment-tool h6 {
    font-weight: 400;
    /*font-family: monospace !important;
text-transform: none;*/
}

.assignment-tool header {
    background: #555;
    padding: 10px 20px 0 10px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.assignment-tool header h2 {
    color: white;
    letter-spacing: 0.05em;
}

.assignment-tool .section,
.key {
    font-family: @header-font;
}

.assignment-tool .section {
    background: #eee;
}

.assignment-tool .section .heading {
    background: white;
    padding-bottom: 20px;
    text-align: center;
}

.assignment-tool .section .heading,
.assignment-tool .section .heading h3 {
    color: @abet-gray;
    font-weight: 200;
}

.assignment-tool .section table.single-cell tr:first-of-type td {
    padding-top: 0;
}

.assignment-tool .section table.single-cell .help-block {
    margin-bottom: 10px;
}

.assignment-tool .notice table .help-block {
    margin: 0;
}

.assignment-tool table.instruction-list td {
    font-size: 15px !important;
    padding-top: 8px;
    vertical-align: top;
}

.assignment-tool table.instruction-list .help-block {
    font-weight: 500 !important;
    letter-spacing: .05em;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.assignment-tool .filters {
    margin-bottom: 40px;
}

.assignment-tool .section.with-instructions {
    background: #f8f8f8;
    margin-bottom: 40px;
}

.assignment-tool .section.matrix-instructions {
    margin-bottom: 20px;
}

.assignment-tool .section.with-instructions .heading {
    margin-bottom: 0;
}

.assignment-tool .inner {
    padding: 5px 10px 10px;
}

.assignment-tool .section.with-instructions .inner {
    border: 1px solid #ddd;
    border-bottom-width: 2px;
    border-right-width: 2px;
    padding: 10px 50px 20px;
}

.assignment-tool .section,
.assignment-tool .filters .results,
.assignment-tool .summary {
    margin-top: 30px;
}

.tooltip-dir label {
    margin-bottom: 0;
}

.assignment-tool table.assignment-summary {
    background: white;
}

.tooltip-dir:hover,
.tooltip-dir:hover label,
.assignment-summary th:hover,
.assignment-summary th:hover label {
    cursor: pointer;
}

.assignment-tool .multiselect-container {
    position: relative;
    width: 100%;
}

.assignment-tool .multiselect-container button {
    text-align: left;
}

.assignment-tool .filters .section {
    height: 380px;
}

.assignment-tool .filters .section label {
    text-transform: capitalize;
}

.assignment-tool-body .btn-container button {
    padding: 10px;
    width: 100%;
}

.assignment-tool .build-matrix-btns {
    margin-bottom: 40px;
}

.assignment-tool-body .build-matrix-btns.btn-container span,
.assignment-tool-body .build-matrix-btns.btn-container button {
    margin: 0 !important;
    width: 100%;
}

.assignment-tool-body .btn-container button {
    border-bottom: 2px solid #c1c1c1;
    border-right: 2px solid #c1c1c1;
}

.assignment-tool-body .btn-container button.build-matrix {
    border-top: unset;
    border-left: unset;
    border-bottom-color: @abet-orange - 30%;
    border-right-color: @abet-orange - 30%;
}

.assignment-tool-body .btn-container button {
    background: #ccc;
    border-radius: unset;
    color: white;
    font-family: @header-font;
    font-weight: 600 !important;
    letter-spacing: 0.05em;
}

.assignment-tool-body .btn-container button:not(.build-matrix):hover {
    background: #bbb;
    border-color: #b1b1b1;
}

.assignment-tool-body .btn-container button.build-matrix:hover {
    background: @abet-orange - 15%;
    border-color: @abet-orange - 30%;
}

.assignment-tool-body .btn-container button,
.assignment-tool .section .heading button {
    text-transform: uppercase;
}

.assignment-tool-body button.btn-sm.btn-default,
.notice .title .btn.btn-sm.btn-default {
    border-bottom-width: 2px;
    border-right-width: 2px;
    padding: 0 5px;
    font-weight: 400;
}

.assignment-tool .build-matrix-info {
    margin: 30px 0 40px;
    padding-bottom: 20px;
}

.assignment-tool .matrix-call-to-action {
    padding: 20px 40px;
}

.assignment-tool .build-matrix-info .build-matrix-btns {
    position: relative;
}

table [uib-popover-template]:not([popover-enable="false"]):not(.icon):not(.btn) {
    border-bottom: 1px dashed #aaa;
    padding-bottom: 1px;
    margin-bottom: 5px;
}

table [uib-popover-template]:not([popover-enable="false"]):not(.icon):not(.btn):hover,
.assignment-summary .tooltip-dir label:hover {
    border-bottom-color: @abet-orange;
}

a.icon {
    border-bottom: none;
}

.content table [popover-enable="true"] a {
    border-bottom: none;
}

.assignment-tool div.popover h3 {
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;
}

.assignment-tool div.popover li {
    margin: 0;
    padding: 0;
}

body.modal-open .popover[title="Assigned PEVs"] {
    z-index: 0;
}

.outer-matrix .spinner {
    margin: 60px 0;
}
/*********** MATRIX ************/
/* Fixed Headers */
table#matrix-grid {
    border-collapse: separate;
    font-family: monospace;
    margin: 0 auto;
    overflow-x: scroll;
    width: 100%;
}

table#matrix-grid tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
}

table#matrix-grid,
table#matrix-grid thead,
table#matrix-grid tbody {
    display: block;
}

table#matrix-grid thead tr,
table#matrix-grid tbody tr td:first-of-type {
    background-color: #e5e5e5;
}

table#matrix-grid tr td:first-of-type {
    position: relative;
    z-index: 20;
}

table#matrix-grid thead td {
    border-top-width: 2px;
    border-bottom-width: 2px;
}

table#matrix-grid thead td:first-child {
    background: white;
    border-top: none;
    border-left: none;
}

table#matrix-grid tbody tr:first-of-type td {
    border-top: none;
}

table#matrix-grid tbody tr td:first-of-type {
    border-left-width: 2px;
}

table#matrix-grid tbody tr:last-of-type td {
    border-bottom-width: 2px;
}
/* End Fixed Headers */
.matrix {
    margin-bottom: 40px;
}

.matrix table thead span {
    display: inline;
}

.matrix table tr td {
    position: relative;
    border: 1px solid #ccc;
}

.matrix table tr td.assignable-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.matrix table td.assignable-cell div.cell-content {
    padding: 20px 0;
}

.matrix table.assignment-summary td:first-of-type {
    background: white;
}

.matrix ul.dropdown-menu {
    padding: 0;
}

.matrix td.assignable-cell i {
    width: 100%;
}

.matrix td.assignable-cell:not(.has-conflicts):not(.excom-disabled):not(.disabled):hover,
.matrix td.assignable-cell:not(.has-conflicts):not(.excom-disabled):not(.disabled) div[aria-expanded="true"],
.matrix td.assignable-cell.assigned:hover,
.matrix td.assignable-cell.assigned div[aria-expanded="true"] {
    cursor: pointer !important;
}

.matrix td.assignable-cell div i.fill-space {
    visibility: hidden;
}

.matrix td.assignable-cell i.show-on-hover,
.matrix td.assignable-cell:hover i.fill-space,
.matrix td.assignable-cell:not(.released):hover i.hide-on-hover,
.matrix td.assignable-cell div[aria-expanded="true"] i.fill-space,
/* Hide assignment icons */
.matrix td.assignable-cell.unassigned span.assigned,
/* Hide unassigned icons */
.matrix td.assignable-cell.assigned span.unassigned,
.matrix td.assignable-cell.has-conflicts span.unassigned,
.matrix td.assignable-cell.has-date-conflicts span.unassigned,
.matrix td.assignable-cell.excom-disabled span.unassigned,
/* Hide conflict icons*/
.matrix td.assignable-cell:not(.has-conflicts) span.conflicts,
/* Hide date conflict icons */
.matrix td.assignable-cell.assigned span.date-conflicts,
.matrix td.assignable-cell.has-conflicts.has-date-conflicts span.date-conflicts,
.matrix td.assignable-cell:not(.has-date-conflicts) span.date-conflicts,
/* Hide dropdown menu */
.matrix td.assignable-cell.assigned ul.dropdown-menu,
.matrix td.assignable-cell.has-conflicts ul.dropdown-menu,
.matrix td.assignable-cell.excom-disabled ul.dropdown-menu,
.matrix td.assignable-cell.disabled ul.dropdown-menu,
.matrix td.assignable-cell.pev-mode.has-date-conflicts ul.dropdown-menu,
.matrix td.assignable-cell.unassigned:not(:hover) div[aria-expanded="true"] i.hide-on-hover {
    display: none;
}

.matrix td.assignable-cell:not(.disabled):not(.released):hover i.show-on-hover,
.matrix td.assignable-cell:not(.disabled):not(.released):not(.assigned) div[aria-expanded="true"] i.show-on-hover {
    display: block;
}

.matrix .excom,
.matrix .excom-disabled {
    background: #D9F0D1;
}

.matrix td.assigned.pev,
.matrix td.assigned.teamchair,
.key .assigned.pev i.fa-user,
.key .assigned.teamchair i.fa-user {
    background: #bbb;
}

.matrix td.assigned {
    border-color: #999;
}

.matrix td.assigned.observer,
.matrix td.assigned.co-teamchair,
.key .assigned.observer i.fa-user,
.key .assigned.co-teamchair i.fa-user {
    background: #CFE2E8;
}

.matrix td.assigned.editor,
.key .assigned.editor i.fa-user {
    background: #fee5d9;
}

.matrix .released i.fa {
    color: #a94442;
}

.matrix i.fa-exclamation-circle {
    color: #999;
}

.matrix .key .assigned i {
    padding: 2px 3px;
}

.matrix td .warning-msg {
    display: none;
}

.matrix .key i.fa-exclamation,
.matrix td:not(.disabled).has-another-team-assignment .warning-msg {
    background: #ffff99;
    font-size: .8em;
    margin: 0;
}

.matrix .key i.fa-exclamation {
    padding: 4px 0;
    text-align: center;
    width: 30px;
}

.matrix td:not(.disabled).has-another-team-assignment .warning-msg {
    display: inline-block;
    left: 0;
    top: 0;
    position: absolute;
    padding: 3px 10px;
}

.matrix .has-assignments .index,
.matrix .key .has-assignments {
    background: #ffc266;
}

.matrix .index {
    border-radius: 8px;
    padding: 0 4px;
}

.cssgradients .matrix .disabled,
.cssgradients .matrix .has-conflicts {
    background: repeating-linear-gradient( 135deg, #E3E3E3, #E3E3E3 2px, #FFF 2px, #FFF 8px );
}

.cssgradients .matrix .has-conflicts.excom-disabled {
    background: repeating-linear-gradient( 135deg, #aaa, #aaa 2px, #D9F0D1 2px, #D9F0D1 8px );
}

.matrix .key {
    font-family: @header-font;
    font-size: 14px;
    text-align: center;
}

.matrix .key i {
    margin-right: 6px;
}

.matrix .key .key-col {
    display: inline-block;
    text-align: left;
}

.matrix .key .tooltip-dir {
    display: inline;
}

.matrix .key .tooltip-dir label {
    font-size: .75em;
}

.matrix .key .key-icon {
    margin-bottom: 8px;
}

.matrix .key .key-icon.color-block {
    padding-left: 30px;
}

.matrix .key span.title-container {
}

.matrix .key span {
    padding-left: 5px;
    padding-right: 30px;
    background: white;
    display: inline-block;
    width: 100%;
}

.matrix table ul.dropdown-menu.last-row {
    margin-top: 0;
    top: 0;
}

.assignment-tool .popover-content {
    font-size: 14px;
}
/* END MATRIX */
.form-group .multiselect-container {
    position: relative;
    width: 100%;
}

.form-group .multiselect-container button.dropdown-toggle {
    text-align: left;
    width: 100%;
    padding-right: 6px !important;
}

div.page-color div#modal-body {
    background: #F0F0F0;
}

.spinner.full-page {
    margin-top: 20%;
    margin-left: 45%;
}

.search-page pre {
    position: relative;
    z-index: 99999;
}

.search-page .spinner {
    margin-top: 15%;
}

.search-fields-container input {
    z-index: 1 !important;
}
/* Progress indicator for file uploads */
.upload-progress {
    display: inline-block;
    width: 307px;
}

.upload-progress div {
    background: @abet-green;
    color: white;
    text-align: center;
    width: 0;
}

.no-results-container {
    margin: 0 auto;
    padding: 5% 0;
    width: 40%;
}

*[class^='col-']:not(*[class$='-12']) .no-results-container {
    width: 100%;
}

.no-results-container p {
    font-size: 1.1em;
}

.modal .datepicker ul.dropdown-menu {
    min-width: 320px;
    position: absolute;
    width: auto;
}

.has-popover {
    color: @abet-orange !important;
}

.has-popover:hover {
    cursor: pointer;
}

i.fa.warning {
    color: @abet-orange !important;
}

.inline {
    display: inline !important;
}

.popover {
    color: @abet-gray !important;
    letter-spacing: 0 !important;
}

.procedures li {
    margin: 10px 0;
}

.panel-heading:hover {
    background: #e5e5e5;
}

.panel-heading:hover h4 a,
.panel-heading:hover h4 i {
    cursor: pointer;
}
/* User Review */
table.align-top td {
    vertical-align: top;
}

table.separate-border {
    border-collapse: separate;
    border-spacing: 18px;
}

table.separate-border td {
    border-radius: 3px;
    padding: 5px;
}

table.separate-border th label {
    margin-bottom: 0;
}

table.separate-border div.preview-rfe {
    margin-bottom: 10px;
}

table.separate-border div.preview-rfe hr {
    margin-top: 0;
    margin-bottom: 10px;
}

table.separate-border td table.striped tr:nth-child(2n) {
    background: #f5f5f5 !important;
}

table.separate-border td table.striped td {
    border: none;
}

table.separate-border.fixed-50 td {
    padding: 10px 20px;
    width: 50%;
}

table .text-left th {
    text-align: left;
}

.criteria-appendix .subtitle {
    text-transform: capitalize;
}

.program-name {
    color: @abet-gray;
}

.program-name,
.item-container span.new-program {
    font-family: @header-font;
    font-weight: 600;
}

.program-name,
.criteria-appendix .subtitle {
    font-size: 16px;
}

.program-name.size {
    font-size: 15px;
}
/* College Directory */
ul.college-directory table td {
    border: none !important;
}

.college-directory table.no-border td:last-child {
    padding-bottom: 14px;
}

.disabled.widget {
    opacity: .5;
    user-select: none;
}

.highlight-selection {
    background: lightyellow !important;
}
/* Email */
.email-message-body {
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-top: 20px;
    padding: 30px 20px;
}
/* Self Study (OSS) */
.self-study-heading {
    display: flex;
    justify-content: space-between;
}

.self-study-heading > div {
    flex-grow: 1;
}

.info-note {
    display: flex;
}
/* Drop Box */
.drop-box {
    background: #fafafa;
    border: 2px dashed #ccc;
    border-radius: 4px;
    margin-bottom: 60px;
    padding: 20px;
    user-select: none;
}

.drop-box:not(.disabled):hover {
    cursor: pointer;
}

.drop-box i {
    margin-right: 5px;
}

.drag-over:not(.disabled),
.drop-box:not(.disabled):hover {
    background: #f6f6f6;
    border-color: #999;
}

.drop-box.disabled {
    background: #fcfcfc;
    border-color: #e5e5e5;
}

.drop-box:not(.disabled):hover h3,
.drop-box:not(.disabled).drag-over h3,
.drop-box:not(.multiupload):hover i,
.drop-box:not(.multiupload).drag-over i {
    color: #aaa;
    border-color: #aaa;
}

.drop-box h3,
.modal-content .drop-box h3 {
    font-weight: 600;
    margin: 20px 0 10px;
    display: inline-block;
}

.drop-box:not(.disabled) h3 {
    color: #ccc;
}

.drop-box.multiupload h4,
.modal-content .drop-box.multiupload h4,
.modal-body .drop-box.multiupload h4 {
    color: @abet-green;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 0;
    margin-top: 10px;
}

.drop-box.disabled h3 {
    color: #e5e5e5;
}

.drop-box.uploaded {
    border-color: @abet-green;
}

.drop-box.error {
    border-color: @red;
}

.drop-box.uploaded h3,
.drop-box.uploaded h5,
.drop-box.uploaded h3 i.fa,
.drop-box.multiupload h4,
.drop-box.multiupload a {
    color: @abet-green;
}

.drop-box.uploaded h3 {
    text-transform: none;
}

.drop-box.error h3,
.drop-box.error h3 i.fa {
    color: @red;
}

.drag-drop-upload-header .btn-group {
    margin-bottom: 5px;
}

.drag-drop-upload-header label.subdued {
    color: #d5d5d5 !important;
}

/* NPC */

table.npc-outer .documents .buttons {
    flex-grow: 0;
    align-self: center;
}

table.npc-outer input {
    margin-bottom: 20px;
}

table.npc-programs tr {
    background: none !important;
}

table.npc-programs section {
    padding-top: 20px;
}

table.npc-programs td:not(.no-padding) {
    padding: 15px;
}

table.npc-programs .actions {
    font-size: 1.4em;
}

table.npc-programs .border {
    border-left: 10px solid #bbb !important;
    padding: 15px !important;
}

table.npc-programs .border.green { border-left-color: @abet-green !important; }
table.npc-programs .border.yellow { border-left-color: @abet-yellow !important; }
table.npc-programs .border.blue { border-left-color: @abet-blue !important; }
table.npc-programs .border.purple { border-left-color: @abet-purple !important; }
table.npc-programs .border.gray { border-left-color: #999 !important; }

table.npc-programs .actions.green { color: @abet-green; }
table.npc-programs .actions.yellow { color: @abet-yellow; }
table.npc-programs .actions.blue { color: @abet-blue; }
table.npc-programs .actions.purple { color: @abet-purple; }
table.npc-programs .actions.gray { color: #999; }

.npc-status {
    background: #eee;
    padding: 4px;
    font-family: Campton;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 3px;
}

table.npc-programs input {
    margin-bottom: 20px;
}

table.npc-programs .identifier {
    margin-bottom: 20px;
}

table.npc:not(:last-of-type) {
    margin-bottom: 50px;
}

table.npc .program-name {
    margin: 10px;
}

table.npc.has-extra-column th.changes { width: 25%; }
table.npc.has-extra-column th.assignment { width: 10%; }
table.npc.has-extra-column th.reviewer { width: 40%; }
table.npc.has-extra-column th.excom { width: 25%; }

table.npc.heading-row tbody:first-of-type tr:nth-of-type(1) td {
    /* title */
    border-top: none;
    border-left: none;
    border-right: none;
}

table.npc.heading-row tbody:first-of-type tr:nth-of-type(2) {
    /* column headings */
    background: none;
}

table.npc.heading-row tr:nth-of-type(2) th:first-of-type {
    border-left: 1px solid #ccc;
}

table.npc.heading-row tr:nth-of-type(2) th:last-of-type {
    border-right: 1px solid #ccc;
}

table.npc.heading-row th {
    padding: 18px 0 5px;
}

.npc-change-tag-container {
    margin-left: 18px;
}

.npc-add-reviewer {
    margin-top: 10px;
}

table.npc-docs-statements th { width: 50%; }
table.npc-changes { margin-bottom: 25px; }

table.npc-changes .change { width: 20%; }
table.npc-changes .status { width: 15%; }
table.npc-changes .details { width: 35%; }
table.npc-changes .reason { width: 20%; }

table.npc-details th {
    white-space: nowrap;
}

table.npc-details tr:not(:last-child) th {
    border-bottom: 1px solid #dadada !important;
}

table.npc-details .shrink-to-fit {
    align-self: center;
}

/* Self Study */
.key-single-line {
    display: inline-block;
    border-radius: 3px;
    padding: 2px 8px 5px;
    background: white;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.key-single-line i {
    margin-right: 5px;
}

.key-single-line span:not(:first-of-type) {
    margin-left: 10px;
}


.self-study-heading p.desc {
    padding: 0 5%;
}

.self-study-heading .form-group {
    margin: 0 auto;
    width: 40%;
}

.self-study-heading .form-group select {
    color: @abet-orange;
    font-family: @header-font;
    font-weight: 600;
    font-size: 20px;
    height: auto;
    text-transform: uppercase;
}
/* Choose Self Study Option */
.option-choose h2,
.option-choose .fa,
.option-choose strong {
    color: #ccc;
}

.option-choose h2,
.option-choose .main p {
    margin-top: 2rem;
}

.option-choose .main p {
    margin-top: 4rem;
}

.option-choose .columns {
    width: 70vw;
    margin: 2rem auto 4rem;
}

.option-choose .columns > div {
    width: 50%;
    padding: 5rem 4vw 8rem !important;
}

.option-choose .columns > div:first-of-type {
    border-right: 1px solid #dadada;
}

.option-choose .main {
    margin-bottom: 5rem;
}

.option-choose .main p {
    font-size: 1.8rem;
}

.option-choose .disclaimer,
.option-choose .help-block {
    width: 75%;
}

.option-choose .disclaimer {
    background: #eee;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 5px;
    text-align: left;
}

.option-choose ul {
    padding-left: 18px;
}

.option-choose .columns > div:hover {
    background: #f5f5f5;
}

.option-choose .columns > div:hover .disclaimer {
    background: #eaeaea;
}

.option-choose .help-block {
    margin-top: 4rem;
}

iframe.hidden-iframe {
    width: 0;
    height: 0;
    border: 0;
    border: none;
    display: block; /* display: none will remove element from DOM  */
    visibility: hidden
}

.item-container.total-summary ul {
    display: flex;
    text-align: center;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

.item-container.total-summary li {
    list-style: none;
    padding: 10px;
}
/* Survey */
.survey-group hr {
    margin: 30px 0;
}
.survey-group h5 {
    margin: 0;
    font-size: 22px;
    text-transform: capitalize;
}
/*.survey-group textarea { margin: 10px 0 0; width: 50%; }*/
.survey-group .text-area {
    margin-bottom: 10px;
}

.survey-group .type-textarea + .type-textarea {
    margin-top: 40px;
}

.survey-group .selection-text-area {
    margin-left: 18px;
    margin-top: 10px;
    width: 70%;
}

.survey-group .comment-text-area {
    margin-top: 20px;
}

.survey-group:not(.has-widgets) .question {
    margin-top: 25px;
}

.survey-group .question-container > ng-include > .question {
    margin-top: 0;
}

.survey-group .line-breaks {
    display: block;
    margin-bottom: 5px;
}

.survey-group .question label {
    margin-bottom: 0;
}

.survey-group .question label + span.help-block {
    margin: 2px 0 10px;
}

.survey-group .question-desc {
    font-size: 0.9em;
    font-style: italic;
}
/* Survey: Widgets */
.survey-group.has-widgets .block-flat .content {
    padding-top: 0 !important;
}

.survey-group.has-widgets .question-desc {
    margin-top: 20px;
}
/* PEV Reference */
.frameless-container.pev-form {
    max-width: 80%;
    margin: 0 auto;
}

.ck-editor {
    line-height: 20px;
    min-height: 100px;
    padding: 0;
    /*margin: 0;*/
    /*transition: margin 500ms ease-in-out;*/
}

.ck-editor.ck-focused {
    border-color: #ccc !important;
    /*margin: 35px 0 0;*/
}

.ck-editor[read-only="true"] {
    padding: 0;
    min-height: unset;
}

.ck-editor[read-only="true"] p:last-child {
    margin-bottom: 0;
}

.ck-editor:not([read-only=true]) {
    border: 1px solid #e5e5e5 !important;
    padding: 20px !important;
}

.request-btn {
    margin-top: 10px;
}

ul.final-action-results li:not(:last-child):after {
    content: '|';
    margin: 0 4px 0 8px;
}
/* Program Audit (Tab) */
.joint-review-row {
}

.joint-review-row td {
    background: #eee;
    border-top: none !important;
    padding-top: 10px !important;
}

.joint-review-row td label.subdued {
    color: #bbb !important;
}

.joint-review-row td:first-of-type {
    border-radius: 10px 0 0 10px;
    padding-left: 20px;
}

.joint-review-row td:last-of-type {
    border-radius: 0 10px 10px 0;
    padding-right: 20px;
}
/******  STATEMENT TOOL SCROLLING *****/
html.editor-tool-html,
body.editor-tool-body,
#site-container,
.program-audit-tool,
.editor-tool-container,
.column-container {
    height: 100%;
}

body.editor-tool-body {
    overflow: hidden;
}

.column-container > * {
    overflow-y: auto;
}
/****** STATEMENT TOOL ******/
.editor-tool-container .page-header {
    padding-top: 5px;
}

.editor-tool-container .progress-container {
    float: right;
    margin-top: 37px;
    width: 40%;
}

.editor-tool-container .progress {
    height: 14px;
    margin-bottom: 5px;
    background: #e5e5e5;
}

.editor-tool-container p {
    margin: 0;
}

.editor-tool-container p + p,
#collapsible-text p {
    margin-top: 20px;
}

.editor-tool-container .point {
    background-color: inherit;
    border-radius: 18px;
    color: white;
    font-size: 13px;
    height: 34px;
    margin-top: -13px;
    padding: 2px;
    position: absolute;
    text-align: center;
    width: 34px;
    z-index: 1;
}

.editor-tool-container .point:hover {
    cursor: default;
}

.editor-tool-container .progress-bar,
.editor-tool-container .point.active {
    background-color: @abet-green;
}

.editor-tool-container .point:not(.active) {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
}

.editor-tool-container .progress-container .status {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 15px;
    width: 40%;
}

.editor-tool-container .progress-container .status span {
    background: #eee;
    border-radius: 4px;
    font-size: 11px;
    padding: 0 8px;
}

.editor-tool-container .progress-container {
    position: relative;
}

.editor-tool-container .progress-container .point:first-of-type {
    left: 11.83%;
}

.editor-tool-container .progress-container .point:nth-child(2) {
    left: 28.66%;
}

.editor-tool-container .progress-container .point:nth-child(3) {
    left: 45.49%;
}

.editor-tool-container .progress-container .point:nth-child(4) {
    left: 62.32%;
}

.editor-tool-container .progress-container .point:nth-child(5) {
    left: 79.15%;
}

.editor-tool-container .progress-container .point:nth-child(6) {
    left: 95.98%;
}

.editor-tool-container .btn {
    font-size: 0.7em;
}

.editor-tool-container .pa-summary {
    table-layout: fixed;
}

.editor-tool-container .indicators {
    padding-left: 5px;
}

.editor-tool-container .heading-with-button .identifier {
    background: #eee;
    font-family: @header-font;
    font-weight: 400;
}

.editor-tool-container .heading-with-button .identifier:not(:first-of-type) {
    margin-left: 5px;
}

.editor-tool-container .heading-with-button i.fa {
    font-size: 16px;
}

.pa-summary th:first-of-type {
    width: 200px;
}

.capitalize {
    text-transform: capitalize;
}


/****** STATEMENT TOOL - Button Heder / Accessibility Options *******/

.editor-tool-container .options {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.editor-tool-container .options i.fa {
    font-size: 25px;
}

.editor-tool-container .options .columns {
    align-items: center;
}

.editor-tool-container .options .columns > i {
    flex-grow: 0;
    padding: 0;
}

.editor-tool-container .options .columns label {
    padding-left: 5px;
}

.editor-tool-container .options .active label,
.editor-tool-container .options .active i.fa {
    color: @abet-orange;
}

.editor-tool-container a.back-btn,
.editor-tool-container .accessibility-options header {
    background: #ddd;
}

.editor-tool-container a.back-btn,
.editor-tool-container .accessibility-options {
    background: #ddd;
    border-radius: 5px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.editor-tool-container a.back-btn {
    padding: 4px 4px 4px 10px;
    border-top: none;
    border-right: none;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.editor-tool-container a.back-btn .help-block,
.editor-tool-container .accessibility-options header span {
    color: #777;
}

.editor-tool-container .accessibility-options {
    margin-top: 15px;
}

.editor-tool-container .accessibility-options header {
    background: #ddd;
    border-radius: 4px 4px 0 0;
    padding: 3px 15px;
}

.editor-tool-container .accessibility-options header i {
    margin-right: 10px;
}

.editor-tool-container .accessibility-options section {
    background: white;
    border-radius: 0 0 4px 4px;
    padding: 5px 15px;
    text-align: left;
}

.editor-tool-container .accessibility-options.fixed {
    position: absolute;
    right: 40px;
    top: 62px;
}

button.active + button.active {
    border-left-color: white !important;
}
/* Increased Contrast */
.editor-tool-container.increase-contrast,
.editor-tool-container.increase-contrast .notice .main {
    background: #e5e5e5;
}

.editor-tool-container.increase-contrast p,
.editor-tool-container.increase-contrast .ck-editor,
.editor-tool-container.increase-contrast .generated-section,
.editor-tool-container.increase-contrast .generic-section,
.editor-tool-container.increase-contrast .comment-text,
.editor-tool-container.increase-contrast .sub-section div,
.editor-tool-container.increase-contrast .action-details,
.editor-tool-container.increase-contrast .statement-menu {
    color: black;
}

.editor-tool-container.increase-contrast label,
.editor-tool-container.increase-contrast a.back-btn .help-block,
.editor-tool-container.increase-contrast .section-title .program-name,
.editor-tool-container.increase-contrast .btn-default,
.editor-tool-container.increase-contrast .statement-menu div:not(.highlighted) strong {
    color: #444;
}

.editor-tool-container.increase-contrast span.heading-label {
    color: #666;
}

.editor-tool-container.increase-contrast .help-block {
    color: #777;
}

.editor-tool-container.increase-contrast .column-content .instructions li,
.editor-tool-container.increase-contrast .column-content .instructions p {
    color: #888;
}

.editor-tool-container.increase-contrast ::-webkit-scrollbar-track {
    background: #e5e5e5;
}

.editor-tool-container.increase-contrast ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
}

.editor-tool-container.increase-contrast ::-webkit-scrollbar-thumb:hover {
    background: #a5a5a5;
}

.editor-tool-container.increase-contrast hr,
.editor-tool-container.increase-contrast .bg,
.editor-tool-container.increase-contrast .show-hide-text,
.editor-tool-container.increase-contrast .generic-section,
.editor-tool-container.increase-contrast .ck-editor:not([read-only=true]) {
    border-color: #ccc !important;
}

.editor-tool-container.increase-contrast .generic-section.no-findings {
    border-color: #c5c5c5;
}

.editor-tool-container.increase-contrast a.back-btn {
    background: #ccc;
    box-shadow: none;
}
/* Magnify */
.editor-tool-container.magnify .column-menu {
    max-width: 335px;
}

.editor-tool-container.magnify .column-menu .info span {
    font-size: 1.05em;
    line-height: 1.4em;
}

.editor-tool-container.magnify .statement-menu,
.editor-tool-container.magnify h5,
.editor-tool-container.magnify .ck-editor,
.editor-tool-container.magnify .generated-section,
.editor-tool-container.magnify .generic-section,
.editor-tool-container.magnify .generic-section .selection,
.editor-tool-container.magnify .ra-summary,
.editor-tool-container.magnify .action-details,
.editor-tool-container.magnify .sub-section > *,
.editor-tool-container.magnify .action-details .help-block,
.editor-tool-container.magnify .notice .main label,
.editor-tool-container.magnify .comment-text,
.editor-tool-container.magnify p,
.editor-tool-container.magnify .comment-body label {
    font-size: 1.2em;
}

.editor-tool-container.magnify p,
.editor-tool-container.magnify .section-title .program-name {
    line-height: 1.4em !important;
}

.editor-tool-container.magnify .notice .icon i.fa {
    font-size: 1.4em;
}

.editor-tool-container.magnify label.progress-label {
    font-size: 1.1em;
}

.editor-tool-container.magnify .help-block:not(.statement-type-summary) {
    font-size: 1.05em !important;
}

.editor-tool-container.magnify label,
.editor-tool-container.magnify .show-hide-text > *,
.editor-tool-container.magnify button {
    font-size: .9em;
}

.editor-tool-container.magnify .heading-container span.heading-label > * {
    font-size: .7em;
}

.editor-tool-container.magnify .notice .main p,
.editor-tool-container.magnify .notice .main li {
    font-size: 1.45em;
    line-height: 1.25em;
}

.editor-tool-container.magnify .section-title {
    font-size: 2em;
}

.editor-tool-container.magnify .show-hide-text > span {
    padding-top: 5px;
}

.editor-tool-container.magnify .notice .main {
    padding: 31px 35px 25px;
}

.editor-tool-container.magnify .notice > .icon {
    width: 120px;
}
/* Statement Menu */
.editor-tool-container h2.no-records {
    color: #ccc;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #F0F0F0;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
    background: #bbb;
}

.editor-tool-container .column-menu {
    box-sizing: border-box;
    flex-shrink: 0;
    max-width: 275px;
    padding: 20px 2em 2em;
}

.editor-tool-container ol {
    padding-left: 18px;
    margin: 0;
}

.editor-tool-container .main-content {
    margin: 0 auto;
    max-width: 1500px;
    min-width: 1000px;
    padding: 0 3em 0 2em;
}

.editor-tool-container .statement-menu {
    font-family: @header-font;
    font-weight: 300;
    margin-top: 20px;
}

.editor-tool-container .statement-menu strong {
    font-weight: 600;
}

.editor-tool-container .statement-menu span.name:hover {
    cursor: pointer;
}

.editor-tool-container .statement-menu .highlighted:hover span.name {
    cursor: default;
}

.editor-tool-container .statement-menu.no-style > li:not(:last-child) {
    margin-bottom: 35px;
}

.editor-tool-container .statement-menu li.active,
.editor-tool-container .statement-menu li.active li {
    color: @abet-green !important;
}

.editor-tool-container .statement-menu li.active li.finding {
    color: fade(@abet-green, 55%) !important;
}

.editor-tool-container .statement-menu .link-container {
    display: inline-flex;
    align-items: flex-start;
}

.editor-tool-container .statement-menu .link-container:not(.highlighted):hover span.name,
.editor-tool-container .link-container:hover .icon-external {
    background: #d5d5d5;
    margin-left: 5px;
}

.editor-tool-container .statement-menu li .icon-external {
    display: none;
}

.editor-tool-container .link-container:hover .icon-external {
    display: inline;
    padding: 3px 5px;
    /*color: @abet-green;*/
}

.editor-tool-container .link-container.highlighted:hover .icon-external {
    background: @abet-green;
    color: white;
}

.editor-tool-container .statement-menu .icon-external:hover {
    background: #bbb;
    cursor: pointer;
}

.editor-tool-container .statement-menu .highlighted span.name,
.editor-tool-container .statement-menu .link-container:hover span.name {
    display: inline-block;
}

.editor-tool-container .statement-menu .highlighted span.name,
.editor-tool-container .statement-menu .link-container:hover span.name,
.editor-tool-container .statement-menu li .icon-external {
    border-radius: 5px;
    padding: 0 5px;
}

.editor-tool-container .statement-menu .highlighted span.name {
    background: @abet-green;
    color: white !important;
}

.editor-tool-container .statement-menu ul ul li {
    margin: 0;
}

.editor-tool-container .statement-menu ul ul li:first-of-type {
    margin-top: 5px;
}
/*.editor-tool-container .statement-menu span.icon-active {
margin-right: -21px;
padding-left: 3px;
}*/
.editor-tool-container .statement-menu ul li {
    margin-top: 8px;
}

.editor-tool-container .statement-menu ul ul li {
    font-size: 0.9em;
    color: #999;
}

.editor-tool-container .statement-menu ul ul li:first-of-type {
    margin-top: 10px;
}
/* Generated Section */
.editor-tool-container .generated-section label {
    margin-bottom: 0 !important;
}

.editor-tool-container .generated-section ul li {
    margin-top: 15px;
}

.editor-tool-container .generated-section.after-visit li:only-child {
    margin-left: -30px;
}
/* Statement Instructions */
.editor-tool-container .master-buttons {
    display: flex;
    margin-bottom: 30px;
}

.editor-tool-container .master-buttons .btn {
    font-size: .85em;
    margin: 0;
}

.btn.has-icon {
    padding-top: 6px;
    padding-bottom: 6px;
}

.editor-tool-container .master-buttons .btn-group {
    /*align-self: center;*/
    flex-grow: 1;
    align-self: center;
}

.editor-tool-container .column-content .instructions {
    margin: 40px 0 0;
}

.editor-tool-container .column-content .instructions p,
.editor-tool-container .column-content .instructions li,
.editor-tool-container .column-content .notice .main p,
.editor-tool-container .column-content .notice .main li,
.editor-tool-container .sub-section .selection {
    font-family: @header-font;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.03em;
}

.editor-tool-container .column-content .instructions p,
.editor-tool-container .column-content .instructions li,
.editor-tool-container .column-content .notice .main p,
.editor-tool-container .column-content .notice .main li {
    color: #aaa;
}

.editor-tool-container .column-content .instructions li span {
    color: #777;
}

.editor-tool-container .column-content .instructions ul {
    list-style: none;
}

.editor-tool-container .column-content .instructions ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #696767; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.editor-tool-container .sub-section .selection {
    color: #bbb;
}

.editor-tool-container .read-only .sub-section.status-details label {
    margin-bottom: 0 !important;
}

.editor-tool-container .column-content .instructions p label {
    /*font-size: 16px;*/
    margin-bottom: 0;
    margin-right: 5px;
}

.editor-tool-container .column-content .instructions .key-container {
    flex-shrink: 0;
}

.editor-tool-container .column-content .instructions .key {
    background: #eee;
    border-radius: 3px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    padding: 5px 8px;
    width: auto;
}

.editor-tool-container .column-content .instructions .key li {
    margin-bottom: 0;
}

.editor-tool-container .column-content .instructions .key tr:first-of-type td {
    padding-top: 8px;
}

.editor-tool-container .column-content .instructions .key tr:last-of-type td {
    padding-bottom: 8px;
}

.editor-tool-container .column-content .instructions .key td:first-of-type {
    padding: 2px 8px;
    text-align: center;
}

.editor-tool-container .column-content .instructions .key td:last-of-type {
    padding: 0 8px 2px 0;
}

/* Statement Main Content */
.editor-tool-container .column-container {
    display: flex;
}

.editor-tool-container .column-content,
.editor-tool-container .master-buttons {
    position: relative;
    padding: 0 50px;
    transition: width 700ms;
    width: 100%;
}

.editor-tool-container .inner-section {
    margin-left: 44px;
}

.editor-tool-container .column-content:not(.read-only) .statement-section {
    margin-bottom: 70px;
}

.editor-tool-container .column-content.read-only .statement-section {
    margin-bottom: 50px;
}

.editor-tool-container .generic-section {
    border: 1px solid #e5e5e5;
    padding: 20px;
}

.editor-tool-container .generic-section.no-findings {
    border-color: #d5d5d5;
    border-style: dashed;
}

.editor-tool-container .heading-with-button {
    display: flex;
}

.editor-tool-container .heading-container h5 {
    height: 27px;
}

.editor-tool-container .heading-container:not(.heading-with-button) h5,
.editor-tool-container .heading-container:not(.heading-with-button) label {
    margin: 0 0 10px 0;
}

.editor-tool-container .heading-with-button h5,
.editor-tool-container .heading-with-button label {
    flex-grow: 1;
    margin: 0;
}

.editor-tool-container .heading-with-button button {
    margin: 0;
    margin-bottom: 0 !important;
}

.editor-tool-container .heading-container button {
    border-bottom: none;
}

.editor-tool-container .heading-with-button span {
    margin: 0;
    color: @body-color;
}

.editor-tool-container .section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    left: 0;
    line-height: 0.5;
    background: white;
    margin-top: 25px;
    font-size: 24px;
}

.editor-tool-container .section-title .fa {
    margin-right: -1.3em;
}

.editor-tool-container .section-title .program-name {
    color: #696767;
    font-size: inherit;
    line-height: 1em;
    margin-left: 44px;
    margin-right: 10px;
    max-width: 700px;
}

.editor-tool-container .section-title .response-type {
    margin-top: 30px;
}

.editor-tool-container .section-title-container {
    position: relative;
    margin: -20px 0 75px;
}

.editor-tool-container .bg {
    border-bottom: 1px solid #ddd;
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    height: 0.5em;
    margin-bottom: 0.5em;
}

.editor-tool-container .add-response-btn,
.editor-tool-container .add-response-btn i {
    color: #777;
}

.editor-tool-container .editor-footer-container:not(.for-read-only) .add-response-btn {
    border-top: none;
}

.editor-tool-container .editor-footer-container.for-read-only .add-response {
    margin-top: 40px;
}

.editor-tool-container .editor-footer-container .add-response .help-block {
    margin-top: 0;
}

.editor-tool-container .statement-section .bottom {
    margin-top: 20px;
}

.editor-tool-container .statement-section .bottom.columns > * {
    /*width: 50%;*/
}

.editor-tool-container .columns.bottom > *:nth-child(1) {
    padding-left: 0;
}

.editor-tool-container .bottom.box {
    border-left: 8px solid #ddd;
    padding-left: 30px;
    margin-top: 35px;
}

.editor-tool-container .bottom.box + .bottom.box {
    margin-top: 55px;
}


.editor-tool-container .sub-section {
    margin-top: 30px;
}

.editor-tool-container .sub-section input[type="radio"] {
    margin: 0;
}
/* Statement Sidebar */
.editor-tool-container .sidebar {
    box-sizing: border-box;
    max-width: 500px;
    min-width: 250px;
}

.editor-tool-container .sidebar.clean {
    border-left: 1px solid #e5e5e5;
    padding: 0 20px 40px 30px;
}

.editor-tool-container .sidebar.bold {
    font-family: @header-font;
    border-left: 10px solid #FF6C2C;
    padding: 20px 40px 40px 30px;
    background: #f6f6f6;
    margin-right: 40px;
}

.editor-tool-container .sidebar .change-history li {
    box-shadow: rgba(0,0,0,.06) 1px 2px;
    background: white;
    padding: 2px 5px 5px;
    border-radius: 3px;
    margin-top: 10px;
}

.editor-tool-container .sidebar .change-history li:hover {
    background: none;
    box-shadow: none;
}

.editor-tool-container .sidebar hr {
    border-color: #ddd;
}

.editor-tool-container .sidebar h2.no-records {
    font-size: 1.3em;
    line-height: 1.3em;
    margin-top: 80px !important;
}

.editor-tool-container .sidebar .instructions p {
    margin-bottom: 10px;
    line-height: 20px;
    color: @abet-gray;
    font-weight: 500;
}

.editor-tool-container .comment-header {
    margin-bottom: 10px;
}

.editor-tool-container .comment-header .title {
    display: flex;
    align-items: center;
}

.editor-tool-container .comment-header .title .reply-icon {
    align-self: center;
    flex-shrink: 2;
    padding: 0;
}

.editor-tool-container .comment-body {
    background: #f4f4f4;
    font-size: .9em;
    padding: 5px 8px;
}

.comment-date:before {
    content: '\00B7';
    padding: 0 5px;
}

.editor-tool-container .comment label {
    text-transform: capitalize;
    font-weight: 500;
}

.editor-tool-container .comment:not(.first):not(.reply) {
    margin-top: 20px;
}

.editor-tool-container .section-name {
    color: @abet-green;
    display: block;
}

.editor-tool-container .comments {
    flex-grow: 0;
    padding-left: 40px !important;
    min-width: 20vw;
    max-width: 20vw;
}

.editor-tool-container .comments .section-name {
    margin: 40px 0;
}

.editor-tool-container .sidebar .changes .section-name {
    margin: 40px 0 20px;
}

.editor-tool-container .section-name:before {
    content: "\000F1E";
    text-align: center;
    display: block;
    margin: 0 0 5px 0;
    font-size: 2em;
}

.editor-tool-container .section-comments .comment:not(.reply):not(.first) {
    margin-top: 30px;
}

.editor-tool-container .comment.reply {
    margin-top: 10px;
}

.editor-tool-container .comment.reply,
.editor-tool-container .reply-form {
    margin-left: 40px;
}

.editor-tool-container .reply-form {
    margin-bottom: 20px;
    margin-top: 20px;
}

.editor-tool-container .comment-links {
    margin-top: 10px;
}

.editor-tool-container .comment-links li {
    display: inline;
    margin-right: 3px;
}

.editor-tool-container .comment-links li:not(:last-child):after {
    content: "\002022";
    margin-left: 3px;
}

.editor-tool-container .comment-links:not(.is-own-comment):not(.is-reply) .highlight:after {
    content: "" !important;
}

.editor-tool-container .comment a {
    color: #aaa;
    display: inline-block;
    border-bottom: none !important;
}

.editor-tool-container .comment a:hover {
    text-decoration: underline;
}

.identifier,
.editor-tool-container .role {
    background: #999;
    border-radius: 3px;
    color: white;
    font-size: 12px;
    padding: 2px 5px;
}

.identifier {
    margin-top: 0;
}

.align-center {
    align-self: center;
}

.align-end {
    align-self: flex-end;
}

.editor-tool-container .comments .role {
    margin-right: 10px;
}

.editor-tool-container .role-border {
    border-left: 4px solid #ccc;
}

.editor-tool-container .ck-editor .role {
    font-size: 16px;
    padding: 1px 0;
}

.ck-comment-nostyle {
    background-color: inherit !important;
    color: inherit !important;
}

.identifier.grey {
    color: #666;
}

.editor-tool-container .role.tc,
.identifier.draft,
.identifier.yellow {
    background: @abet-yellow;
    color: inherit;
}

.editor-tool-container .role.editor-1,
.identifier.final,
.identifier.purple {
    background: @abet-purple;
}

.editor-tool-container .role.editor-2,
.identifier.post-30.day,
.identifier.post-30-day,
.identifier.blue {
    background: @abet-blue;
}

.editor-tool-container .role.adjunct,
.identifier.adj,
.identifier.green {
    background: @abet-green;
}

.identifier.tag {
    background: #e5e5e5;
    color: #666;
    font-size: 14px;
    padding: 4px 8px;
    border-left: 8px solid #666;
}

.identifier.tag.yellow { border-color: @abet-yellow; }
.identifier.tag.purple { border-color: @abet-purple; }
.identifier.tag.blue { border-color: @abet-blue; }
.identifier.tag.green { border-color: @abet-green; }

.editor-tool-container .role-border.tc {
    border-left-color: @abet-yellow;
}

.editor-tool-container .role-border.editor-1 {
    border-left-color: @abet-purple;
}

.editor-tool-container .role-border.editor-2 {
    border-left-color: @abet-blue;
}

.editor-tool-container .role-border.adjunct {
    border-left-color: @abet-green;
}

.editor-tool-container mark.role.editor-1,
.editor-tool-container mark.role.editor-2 {
    color: white;
}

.editor-tool-container .role.statement-type {
    color: #222;
}

.editor-tool-container .sidebar .form {
    display: block;
}

.editor-tool-container .sidebar .reply-form {
    margin-top: 20px;
}

.editor-tool-container .form .buttons {
    margin-top: 10px;
}

.editor-tool-container .sidebar .changes table {
    margin: 20px 0;
}

.editor-tool-container aside table.no-padding td {
    padding: 0;
}

.editor-tool-container .global-warning {
    background: #f1e5bc;
    /*box-shadow: 0 2px 3px rgba(0,0,0,0.1);*/
    left: 0;
    padding: 2px 10px;
    /*position: fixed;*/
    text-align: center;
    width: 100%;
    z-index: 99999;
}

.editor-tool-container .column-content .editor-summary,
.editor-tool-container .column-content .read-only-summary {
    margin-top: 15px;
}

.editor-tool-container .column-content.read-only .editor-summary,
.editor-tool-container .column-content.change-view .editor-summary,
.editor-tool-container .column-content.read-only .read-only-summary,
.editor-tool-container .column-content.change-view .read-only-summary {
    margin-top: 5px;
}

.editor-tool-container .column-content .editor-summary p,
.editor-tool-container .column-content .read-only-summary p {
    margin-top: 15px;
}

.editor-tool-container .recommended-action {
    margin-bottom: 70px;
}

span.selection:hover {
    color: @abet-orange;
    cursor: pointer;
}

label.orange {
    color: @abet-orange;
}

.editor-tool-container .statement-section .btn-default {
    border-color: #ccc;
}

.editor-tool-container .interim-editor .ck-editor {
    margin-bottom: 45px;
}

.editor-tool-container .interim-summary {
    border-left: 8px solid @abet-orange;
    font-size: 15px;
    font-style: italic;
    padding-left: 30px;
    margin-top: 20px;
}

.editor-tool-container .interim-summary p:first-of-type {
    margin-top: 0;
}

.editor-tool-container #collapsible-text {
    height: auto;
    max-height: 2000px;
    overflow: hidden;
    transition: 1s ease-in-out;
}

.editor-tool-container #collapsible-text.collapsed {
    max-height: 0;
}

.editor-tool-container .show-hide-text {
    border-top: 1px solid #ddd;
}

.editor-tool-container label.empty {
    border: 1px dashed #ccc;
    border-radius: 5px;
    display: block;
    margin-top: 10px;
    padding: 1em;
    text-align: center;
    user-select: none;
}
/* Statement Read Only */
.editor-tool-container .read-only table.key,
.editor-tool-container .read-only #preview-btn,
.editor-tool-container .read-only .section-heading-buttons,
.editor-tool-container .change-view .section-heading-buttons,
.editor-tool-container .read-only #add-finding-btn,
.editor-tool-container .read-only #edit-recommended-action,
.editor-tool-container .change-view #edit-recommended-action,
.editor-tool-container .read-only:not(.comment-view) .ck-editor,
.editor-tool-container .read-only .add-response,
.editor-tool-container .change-view .add-response,
.editor-tool-container .change-view .ck-editor,
.editor-tool-container .ck-editor[read-only="true"].no-text,
.editor-tool-container .editor-summary,
.editor-tool-container label.empty,
.editor-tool-container .ra-summary,
.editor-tool-container .read-only .ra-end-date .ra-edit,
.editor-tool-container .read-only .ra-start-date .ra-edit,
.editor-tool-container .change-view .ra-end-date .ra-edit,
.editor-tool-container .change-view .ra-start-date .ra-edit,
.editor-tool-container .read-only .heading-label.edit {
    display: none;
}

.editor-tool-container .column-content.read-only:not(.comment-view) .editor-summary,
.editor-tool-container .column-content.change-view .editor-summary,
.editor-tool-container .column-content.read-only .sub-section:not(.read-only-due-response) label.empty,
.editor-tool-container .column-content .sub-section.read-only-due-response label.empty,
.editor-tool-container .column-content.read-only .status-summary,
.editor-tool-container .column-content.read-only .ra-summary,
.editor-tool-container .column-content.change-view .ra-summary {
    display: block;
}

.editor-tool-container tr.change-history td {
    padding-bottom: 10px !important;
}

.editor-tool-container tr.change-history td:first-child {
    padding-right: 4px;
}

.editor-tool-container span.heading-label {
    background: #e5e5e5;
    border-radius: 5px;
    color: #777;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin-left: 8px;
    padding: 1px 5px;
}

.editor-tool-container span.heading-label.comment-indicator {
    background: @abet-orange;
    color: white;
}

.editor-tool-container span.heading-label span {
    font-size: 11px;
}

.editor-tool-footer {
    /*margin: 0 auto 60px;
width: 60%;*/
}

.editor-tool-footer > * {
    /*align-self: center;*/
}
/* Evaluation */
.evaluation label {
    margin: 20px 0;
}

.evaluation table {
    margin-bottom: 30px;
}

.feedback p:last-of-type {
    margin-bottom: 0;
}

.evaluation .selection {
    border-color: @abet-green;
    background: @abet-green;
}

.evaluation .selection h4 {
    color: white;
}

.help-block.pevcs,
.help-block.results {
    margin-bottom: 10px;
}
/* OFFLINE VIEW */
#offline-header,
.offline #head-nav,
.offline #main-sidebar,
.offline .sidebar-toggle {
    display: none;
}

.offline #breadcrumbs-container {
    margin-left: 0;
}

#offline-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background: black;
    border-radius: unset;
    font-family: @header-font;
    height: 65px;
    padding: 0 25px 0;
    text-align: center;
}

#offline-header > * {
    flex-grow: 1;
}

#offline-header .sidebar-logo {
    width: 36px;
}

#offline-body {
    padding: 15px 20px;
}

.unavailable {
    opacity: 0.4;
}

.dashboard-links-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 20px;
}

.dashboard-links-container img {
    object-fit: cover;
    width: 100%;
    max-width: 5vw;
}

.hide {
    display: none;
}

@media (min-width: 1700px) {
    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-11 {
        width: 91.66666667%;
    }

    .col-xl-10 {
        width: 83.33333333%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-8 {
        width: 66.66666667%;
    }

    .col-xl-7 {
        width: 58.33333333%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-5 {
        width: 41.66666667%;
    }

    .col-xl-4 {
        width: 33.33333333%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-2 {
        width: 16.66666667%;
    }

    .col-xl-1 {
        width: 8.33333333%;
    }
}

.col-xl-6 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
/* generic margin utility classes */
.generate-margins(5);

.generate-margins(@n, @i: 0) when (@i =< @n) {
    .m-@{i} {
        margin: ~"@{i}em";
    }

    .mt-@{i} {
        margin-top: ~"@{i}em";
    }

    .mb-@{i} {
        margin-bottom: ~"@{i}em"
    }

    .ml-@{i} {
        margin-left: ~"@{i}em";
    }

    .mr-@{i} {
        margin-right: ~"@{i}em";
    }

    .generate-margins(@n, (@i + 1));
}

